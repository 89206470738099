<template v-if="show">
  <van-overlay style="background: transparent"
               v-model:show="show" @click="show=false;$emit('close')"/>

  <Transition>
    <div class="popover" ref="popover"
         v-if="!isLoading && word && reference"
         :style="floatingStyles">
      <template v-if="matchedSimpleWords && activeWord">
        <div class="matched-words" v-if="matchedSimpleWords.length>1">
        <span :class="{label:true,active:activeWord.word===item.word}"
              v-for="(item,index) in matchedSimpleWords" :key="index"
              @click="activeWord=item; playAudio()">{{ item.word }}</span>
        </div>
        <p class="word">{{ activeWord.word }}</p>
        <p class="pron">
        <span class="form" @click="invertPronFormPreference();playAudio()">{{
            pronFormPreference === 'us' ? '美' : '英'
          }}<svg
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"><path
              d="M261.283306 278.655388C243.083715 263.735652 214.939304 264.990956 198.430798 281.480084 181.939876 297.969213 183.321704 323.452412 201.539912 338.408914 243.229552 372.643525 267.156076 421.650809 267.156076 472.836754 267.156076 523.949165 243.174733 572.993214 201.321674 607.339176 191.612674 615.297489 186.703873 626.228613 186.703873 637.215414 186.703873 646.854469 190.503901 656.567057 198.249794 664.285864 214.758299 680.758184 242.902711 682.013488 261.102301 667.055936 321.500408 617.440435 356.153686 546.662292 356.153686 472.836754 356.152655 398.919826 321.571777 328.142733 261.283306 278.655388L261.283306 278.655388 261.283306 278.655388ZM429.96877 145.851566C412.642132 130.064147 384.479103 129.971706 367.061445 145.685593 349.626204 161.344855 349.571386 186.918394 366.879407 202.687956 446.023217 274.795986 489.638728 370.72328 489.638728 472.835705 489.638728 574.764298 445.967364 670.673735 366.661169 742.910972 358.043359 750.758987 353.715837 761.044077 353.715837 771.310258 353.715837 781.651023 358.07956 792.029604 366.82459 799.876569 384.259831 815.590455 412.405277 815.516923 429.749498 799.728453 525.765888 712.313066 578.63634 596.203213 578.63634 472.835705 578.637375 349.266508 525.820707 233.137746 429.96877 145.851566L429.96877 145.851566 429.96877 145.851566ZM597.200005 12.421667C580.200206-3.625216 552.038212-4.160954 534.293714 11.258803 516.530599 26.677509 515.965869 52.196424 532.984287 68.243307 648.507626 177.337114 712.105866 321.015336 712.105866 472.818896 712.105866 624.327274 648.399024 767.950874 532.766049 877.267378 524.512314 885.059717 520.384411 895.104251 520.384411 905.149835 520.384411 915.730105 524.966372 926.329284 534.093059 934.250831 551.819973 949.650626 580.000586 949.097032 597.017967 933.03229 728.615406 808.647938 801.08486 645.229403 801.08486 472.836754 801.121061 300.11111 728.687807 136.619039 597.200005 12.421667L597.200005 12.421667 597.200005 12.421667Z"></path></svg></span>
          <span class="pronunciation" @click="playAudio">{{
              displayPronunciation !== '//' ? displayPronunciation : ''
            }}</span>
        </p>
        <div class="interpret">
          <p class="item" v-for="(item, index) in JSON.parse(activeWord.interpret)" :key="index">
            <span class="pos">{{ item.p }}</span>&nbsp;
            <span class="definitions">{{ item.i }}</span>
          </p>
          <!-- @formatter:off -->
          <p class="btn-view-details"
             @click="showToast({message: '该功能未实现', position: 'top', duration: 800, teleport: '#card'})">查看详细释义<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path d="M318.57 223.95l322.99 322.99c21.87 21.87 57.33 21.87 79.2 0 21.87-21.87 21.87-57.33 0-79.2l-323-322.99c-21.87-21.87-57.33-21.87-79.2 0-21.86 21.87-21.86 57.33 0.01 79.2z"></path>
            <path d="M729.75 555.95L406.76 878.93c-21.87 21.87-57.33 21.87-79.2 0-21.87-21.87-21.87-57.33 0-79.2l322.99-322.99c21.87-21.87 57.33-21.87 79.2 0 21.87 21.88 21.87 57.34 0 79.21z"></path>
          </svg>
          </p>
          <!-- @formatter:on -->
        </div>
      </template>
      <div id="empty" v-else>
        <img id="empty-icon" :src="noDefinitionIcon" alt=""/>
        <p id="empty-tip">一个词义也没找到</p>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import {ref, computed, watch} from 'vue'
import {flip, offset, useFloating} from '@floating-ui/vue'
import {lookupSimpleWordAndMatchedSimpleWords} from "@/api/simplewords"
import noDefinitionIcon from '@/assets/no-word-definition.webp'
import {ensureWordAudioCached, getWordAudioBlob} from "@/api/files"
import {showToast} from 'vant'

const show = defineModel('show')
const props = defineProps({
  word: String,
  reference: HTMLElement
})
defineEmits(['close'])

const isLoading = ref(false)
watch(show, (showVal) => {
  matchedSimpleWords.value = null
  if (showVal) {
    isLoading.value = true
    lookupSimpleWordAndMatchedSimpleWords(props.word)
        .then(resp => {
          const matchedWords = resp.data
          matchedSimpleWords.value = matchedWords

          for (let i = 0; i < matchedWords.length; i++) {
            const simpleWord = matchedWords[i]
            ensureWordAudioCached(simpleWord.word, true)
            ensureWordAudioCached(simpleWord.word, false)
          }

          activeWord.value = matchedSimpleWords.value[0]
          playAudio()
        })
        .catch(error => console.log(error))
        .finally(() => isLoading.value = false)
  }
})

const popover = ref(null);
const reference = computed(() => props.reference)
const {floatingStyles} = useFloating(reference, popover, {
  transform: false,
  middleware: [offset(8), flip()]
});

const matchedSimpleWords = ref(null)
const activeWord = ref(null)
const pronFormPreference = ref(localStorage.getItem('pronFormPreference') ?? 'us')
const displayPronunciation = computed(() => '/' + (pronFormPreference.value === 'us' ? activeWord.value.usPron : activeWord.value.ukPron) + '/')

const invertPronFormPreference = () => {
  pronFormPreference.value = pronFormPreference.value === 'us' ? 'uk' : 'us'
  localStorage.setItem('pronFormPreference', pronFormPreference.value)
}

const playAudio = () => {
  if (!activeWord.value ||
      activeWord.value.us_pron === '') return

  let audio = document.body.querySelector('#word-audio')
  if (!audio) {
    audio = document.createElement('audio')
    audio.id = 'word-audio'
    document.body.appendChild(audio)
  }

  // 无用后要进行释放操作
  if (audio.src) {
    URL.revokeObjectURL(audio.src)
  }

  getWordAudioBlob(activeWord.value.word, pronFormPreference.value === 'us').then(audioBlob => {
    if (audioBlob == null) {
      showToast({message: '获取音频文件失败', position: 'top', duration: 800, teleport: 'body'})
      return
    }
    audio.src = URL.createObjectURL(audioBlob)
    audio.play()
  })
}
</script>

<style lang="less">
.v-enter {
  &-active {
    transition: scale .25s ease;
  }

  &-from {
    scale: 0.8;
  }
}

.popover {
  left: 8px !important;
  width: 400px;
  padding: 21px 16px;
  border-radius: 8px;
  background-color: #292f44;
  box-shadow: 0 3px 0 #262b3f;
  z-index: 1;

  > #empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 22px;

    > #empty-icon {
      width: 50px;
      opacity: 0.4;
    }

    > #empty-tip {
      color: #43485a;
      font-size: 15px;
      margin: 13px 0;
    }
  }


  > .matched-words {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    color: #8a8d99;
    font-size: 14.1px;
    font-weight: bold;
    margin-bottom: 17px;

    > .label {
      transition: transform .55s;
      padding: 3px 12px;
      margin-right: 8px;
      background-color: #42485a;
      border-radius: 5px;
      border: 1px solid #515667;

      &.active {
        transform: scale(1.07);
        color: #f4a100;
        background: #433f3b;
        border-color: #433f3b;
      }
    }
  }

  > .word {
    color: #f4a100;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  > .pron {
    margin-bottom: 20px;

    > .form {
      padding: 3px 7px;
      color: #a8abb3;
      font-size: 11px;
      border-radius: 12px;
      background-color: #42485a;
      margin-right: 8px;

      > svg {
        margin-left: 4px;
        vertical-align: middle;
        width: 9px;
        fill: #a8abb3;
      }
    }

    > .pronunciation {
      color: #9d9fa9;
    }
  }

  > .interpret {
    color: #e3e4e7;

    > .btn-view-details {
      font-size: 11.5px;
      margin-top: 21px;
      letter-spacing: 0.3px;

      > svg {
        margin-left: 2px;
        //vertical-align: middle;
        width: 9px;
        fill: #e3e4e7;
      }
    }

    > .item {
      margin-bottom: 5px;
      white-space: pre-wrap;

      > .pos {

      }

      > .definitions {
        font-size: 15px;
      }
    }
  }

}
</style>

