<template>
  <div id="card"
       v-if="sense">
    <van-swipe id="sentence-swipe"
               ref="sentenceSwipe"
               :loop="false"
               :initialSwipe="initialSwipe"
               duration="300"
               @change="pauseAudio"
               lazy-render>
      <van-swipe-item class="sentence-swipe-item"
                      v-for="sentence in sense.sentences"
                      :key="sentence.id"
                      @click="playAudio">
        <div id="title">{{ sentence.sourceName }}</div>
        <p class="en">
          <!-- 将句子分割成不同类型的单元 (单词,标点符号,空格等) -->
          <template
              v-for="(unit,index) in splitEnSentenceIntoUnits(sentence.english)"
              :key="index">
            <span
                v-if="unit.type==='word' || unit.type==='boldWord'"
                v-html="unit.value"
                @click.stop="showPopover=true; pauseAudio(); popoverReference=$event.target"></span>
            <span
                v-else-if="unit.type === 'punctuation'">{{ unit.value }}</span>
            <span
                v-else>&nbsp;</span>
          </template>
        </p>
        <p class="cn">{{ sentence.chinese }}</p>
      </van-swipe-item>
      <template #indicator="{ active,total }">
        <div id="indicator"
             v-if="total>1">
          <div v-for="i in total" :key="i"
               :class="{ 'dot': true, 'active': active===i-1 }"></div>
        </div>
      </template>
    </van-swipe>
    <div id="sense">
      <!-- 非简明释义, 有明确的中英文释义 -->
      <template v-if="sense.type!==99">
        <div id="phrase"
             v-if="sense.phraseUsage">
          <span class="type">{{ sense.phraseType }}</span>
          <span class="usage" v-html="sense.phraseUsage"></span>
        </div>
        <div id="definition">
          <!-- 如果是短语的话, 不需要展示释义词性 -->
          <span class="pos"
                v-if="!sense.phraseUsage">{{ sense.pos }}&nbsp;&nbsp;</span>
          <span class="cn">{{ sense.cnDefinition }}&nbsp;&nbsp;</span>
          <div class="en">
            <!-- 将句子分割成不同类型的单元 (单词,标点符号,空格等) -->
            <template
                v-for="(unit,index) in splitEnSentenceIntoUnits(sense.enDefinition)"
                :key="index">
            <span
                v-if="unit.type==='word' || unit.type==='boldWord'"
                v-html="unit.value"
                @click.stop="showPopover=true; pauseAudio(); popoverReference=$event.target;"></span>
              <span
                  v-else-if="unit.type === 'punctuation'">{{ unit.value }}</span>
              <span
                  v-else>&nbsp;</span>
            </template>
          </div>
        </div>
        <!-- 如果是短语有且只有一种形式, 则不用展示, 已在"#definition >#phrase"中展示-->
        <div id="usages"
             v-if="!(sense.phraseUsage && senseUsages.size===1)">
          <div
              v-for="usage in senseUsages" :key="usage" @click="selectUsage(usage)"
              :class="{tag: true, active: usage===activeSentence?.usage}">
            {{ usage }}
          </div>
        </div>
      </template>
      <!-- 简明释义, 只有宽泛的中文释义 -->
      <template v-else>
        <div class="simple-interpret" v-if="sense.simple">
          <p class="item" v-for="(item, index) in JSON.parse(sense.simple.interpret)" :key="index">
            <span class="pos">{{ item.p }}</span>&nbsp;
            <span class="definitions">{{ item.i }}</span>
          </p>
          <!-- @formatter:off -->
          <p class="btn-view-details"
             @click="showToast({message: '该功能未实现', position: 'top', duration: 800, teleport: '#card'})">查看详细释义<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
              <path d="M318.57 223.95l322.99 322.99c21.87 21.87 57.33 21.87 79.2 0 21.87-21.87 21.87-57.33 0-79.2l-323-322.99c-21.87-21.87-57.33-21.87-79.2 0-21.86 21.87-21.86 57.33 0.01 79.2z"></path>
              <path d="M729.75 555.95L406.76 878.93c-21.87 21.87-57.33 21.87-79.2 0-21.87-21.87-21.87-57.33 0-79.2l322.99-322.99c21.87-21.87 57.33-21.87 79.2 0 21.87 21.88 21.87 57.34 0 79.21z"></path>
            </svg>
          </p>
          <!-- @formatter:on -->
        </div>
      </template>
      <p id="swiper-index"
         v-if="sense.swiper">
        {{ `${sense.swiper.index + 1}/${sense.swiper.total}` }}
      </p>
    </div>
  </div>
  <WordSimpleDefinitionPopover
      v-model:show="showPopover"
      :reference="popoverReference"
      :word="popoverWord"
      @close="popoverReference=null"/>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
import WordSimpleDefinitionPopover from "@/components/word-simple-definition-popover.vue"
import {ensureSentenceAudioCached, getSentenceAudioBlob, getSentenceCover} from "@/api/files"
import {lookupSimpleWord} from "@/api/simplewords"
import {showToast} from 'vant'

const props = defineProps({
  sense: {required: true},
  initialSentenceId: {type: Number, default: null}
})

const sense = props.sense
const initialSwipe = ref(0)
if (props.initialSentenceId) {
  initialSwipe.value = Math.max(sense.sentences.findIndex(s => s.id === props.initialSentenceId), 0)
}

// 简明释义单词, 绝大部分都是生僻词
if (sense.type === 99) {
  lookupSimpleWord(sense.word)
      .then(resp => sense.simple = resp.data)
      .catch(error => console.log(error))
}

const senseUsages = ref([])
const sentenceSwipe = ref(null)
const activeSentence = computed(() => sense.sentences.at([sentenceSwipe.value?.state.active]))
const activeSentenceCover = computed(() => `url('${getSentenceCover(activeSentence.value)}')`);

(() => {
  // 利用浏览器的缓存策略preload, 防止过渡时闪烁
  sense.sentences.forEach(sentence => new Image().src = getSentenceCover(sentence))
  sense.phraseUsage = sense.phraseUsage?.replaceAll('|', `<span class="separator">|</span>`)

  senseUsages.value = new Set(sense?.sentences
      .map(s => s.usage)
      .filter(u => u && u.trim().length > 0))
  sentenceSwipe.value?.swipeTo(0)
})();


watch(() => sentenceSwipe.value?.state.active, (active) => {
  if (active === undefined) return

  const sentences = sense.sentences
  const preloadAudio = (index) => {
    const sentence = sentences[index]
    if (sentence) {
      ensureSentenceAudioCached(sentence)
    }
  }

  // 缓存音频文件, 提升使用体验
  preloadAudio(active - 1)
  preloadAudio(active)
  preloadAudio(active + 1)
}, {immediate: true})


const showPopover = ref(false)
const popoverWord = ref(null)
const popoverReference = ref(null)
watch(popoverReference, (newVal, oldVal) => {
  oldVal?.classList.remove("popover-reference")
  newVal?.classList.add("popover-reference")
  popoverWord.value = newVal?.textContent
})

const selectUsage = (usage) => {
  sentenceSwipe.value.swipeTo(
      sense.sentences.findIndex(sentence => usage === sentence.usage))
}
const splitEnSentenceIntoUnits = (sentence) => {
  const regex = /<b>(.*?)<\/b>|[\w'-]+|[^\w\s]|\s/g
  const result = []
  let match

  while ((match = regex.exec(sentence)) !== null) {
    if (/<b>(.*?)<\/b>/.test(match[0])) {   // <b>boldWord</b>
      result.push({type: 'boldWord', value: match[0]})
    } else if (/\w+/.test(match[0])) {      // word
      result.push({type: 'word', value: match[0]})
    } else if (/[^\w\s]/.test(match[0])) {  // 标点符号等
      result.push({type: 'punctuation', value: match[0]})
    } else {                                // 空白符
      result.push({type: 'space', value: ' '})
    }
  }
  return result
}

const playAudio = () => {
  let audio = document.body.querySelector('#sentence-audio')
  if (!audio) {
    audio = document.createElement('audio')
    audio.id = 'sentence-audio'
    document.body.appendChild(audio)
  }

  const currId = audio.getAttribute('audio-id')
  const newId = activeSentence.value.id.toString()
  audio.setAttribute('audio-id', newId)

  // 同一个音频的情况下, 处理不同的播放状态
  //  正在播放中 => 暂停
  //  非播放中 => 播放/重播
  if (audio.src && newId === currId) {
    if (audio.paused) {
      audio.currentTime = 0
      audio.play();
    } else {
      audio.pause()
    }
    return
  }

  // 无用后要进行释放操作
  if (audio.src) {
    URL.revokeObjectURL(audio.src)
  }

  getSentenceAudioBlob(activeSentence.value).then(audioBlob => {
    if (audioBlob === null) {
      audio.src = null
      showToast({message: '获取音频文件失败', position: 'top', duration: 800, teleport: '#card'})
      return
    }

    audio.src = URL.createObjectURL(audioBlob)
    audio.play()
  })
};

const pauseAudio = () => {
  const audio = document.body.querySelector('#sentence-audio')
  audio?.pause()
}

</script>

<style lang="less" scoped>
#card {
  @border-radius: 8px;
  width: 398px;
  margin: 16px;
  line-height: 1.3;

  :deep(.van-toast) {
    --van-toast-background: #292f44;
    --van-toast-text-color: #e3e4e7;
  }

  > #sense {
    position: relative;
    height: 228px;
    background-color: #292f44;
    border-radius: 0 0 @border-radius @border-radius;
    padding: 20px 16px;

    > #phrase {
      padding-bottom: 10px;

      > .type {
        color: #7f838f;
        font-size: 11px;
        font-weight: bold;
        padding: 2px 4px;
        background-color: #31374b;
        border-radius: 5px;
      }

      > .usage {
        margin-left: 10px;
        color: #9d9fa9;

        :deep(>.separator) {
          color: #545869;
        }
      }
    }

    > #definition {
      color: #e3e4e7;
      font-size: 15.8px;
      white-space: pre-wrap;

      > .cn {
        font-size: 15px;
      }

      > .en {
        display: inline;

        span {
          display: inline-block;

          &.popover-reference {
            background-color: #475676;
          }
        }
      }
    }

    > #usages {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 20px;
      color: #8a8d99;
      font-weight: bold;
      font-size: 14.1px;

      > .tag {
        transition: transform .55s;
        padding: 3px 10px;
        margin-right: 14px;
        margin-bottom: 10px;
        border: 1px solid #515667;
        border-radius: 5px;
        background-color: #42485a;
        white-space: nowrap;
      }

      > .tag.active {
        color: #f4a100;
        background-color: #433f3b;
        border-color: #433f3b;
        transform: scale(1.07);
      }
    }

    > .simple-interpret {
      color: #e3e4e7;
      font-size: 14px;

      > .btn-view-details {
        color: #7a7e8b;
        font-size: 11.5px;
        margin-top: 11px;
        letter-spacing: 0.3px;

        > svg {
          margin-left: 2px;
          //vertical-align: middle;
          width: 9px;
          fill: #7a7e8b;
        }
      }

      > .item {
        margin-bottom: 4px;
        white-space: pre-wrap;

        > .pos {
          font-size: 15px;
        }

        > .definitions {
          font-size: 15px;
        }
      }
    }

    > #swiper-index {
      position: absolute;
      right: 17px;
      bottom: 12px;
      color: #7a7e8b;
      font-size: 12px;
    }
  }

  > #sentence-swipe {
    position: relative;
    height: 498px;
    background-color: #212633;
    border-radius: @border-radius @border-radius 0 0;

    & {
      // 卡片背景图片
      &::before {
        background: no-repeat top left / cover v-bind(activeSentenceCover);
        transition: background-image .4s;
        z-index: -2;
      }

      // 卡片背景图片遮罩
      // 两个background可以写在一起,但是可能会导致部分浏览器`transition: background-image`不生效
      &::after {
        background: linear-gradient(to bottom, rgba(33, 38, 51, 0.8), rgba(33, 38, 51, 0.8), #212633 73%, #212633);
        z-index: -1;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-bottom: 100%; // =width
        border-radius: @border-radius @border-radius 0 0;
      }
    }

    .sentence-swipe-item {

      > #title {
        position: absolute;
        top: 17px;
        left: 17px;
        font-size: 14px;
        color: #ada8a2;
        font-weight: 500;
        z-index: 1;
      }

      > .en {
        position: absolute;
        bottom: 110px;
        left: 16px;
        right: 16px;
        font-size: 16.8px;
        font-weight: bold;
        color: #e2e3e4;
        line-height: 1.4;

        :deep(b) {
          font-weight: bold;
          color: #f4a100;

          &.active {
            background-color: #475676;
          }
        }

        span {
          display: inline-block;

          &.popover-reference, :deep(b.popover-reference) {
            background-color: #475676;
          }
        }
      }

      > .cn {
        position: absolute;
        top: 392px;
        left: 16px;
        right: 16px;
        font-size: 15px;
        color: #999bae;
        line-height: 1.4;
      }
    }

    > #indicator {
      position: absolute;
      bottom: 24px;
      display: flex;
      justify-content: center;
      width: 100%;

      > .dot {
        width: 4px;
        height: 4px;
        background-color: #3b404b;
        border-radius: 50%;
        margin: 0 2px;
      }

      > .dot.active {
        background-color: #757880;
      }
    }
  }
}
</style>
