<template>
  <swiper id="swiper"
          v-if="senses"
          @swiper="swiper => swiperRef = swiper"
          @slideChange="onSlideChange"
          :initialSlide="initialSlide"
          :speed="350"
          :threshold="0"
          :modules="[Virtual]"
          :grabCursor="true"
          :slidesPerView="1"
          virtual>
    <swiper-slide
        v-for="(sense, index) in senses"
        :key="index"
        :virtualIndex="index">
      <WordSenseCard
          :sense="sense"
          :initialSentenceId="(sense.id===initialSenseId) ? initialSentenceId : null"/>
    </swiper-slide>
    <div id="pagination">
      <div id="pagination-wrapper">
        <template v-for="(sense, index) in senses" :key="index">
          <span
              :class="{
                  'pagination-bullet': true,
                  core: sense.type===1 || sense.type===3,
                  active: activeIndex===index}"
              :style="{
                  left: bulletLeft + 'px',
                  'scale': ((index===visibleBulletStart || index===visibleBulletEnd) && (index!==minBulletStart && index!==maxBulletEnd)) ? 0.7 : 1}">
            {{ sense.type === 99 ? '简明' : ((sense.type === 1 || sense.type === 3) ? '核心' : '扩展') }}
          </span>
        </template>
      </div>
    </div>
  </swiper>
</template>

<script setup>
import {computed, ref, watch} from 'vue'
import WordSenseCard from "@/components/word-sense-card.vue"
import {Swiper, SwiperSlide} from 'swiper/vue'
import {Virtual} from 'swiper/modules'
import 'swiper/css'
import {lookupWordSenses} from "@/api/wordsenses";

const props = defineProps({
  word: {type: String, required: true},
  initialSenseId: {Number, default: null},
  initialSentenceId: {Number, default: null}
})
const swiperRef = ref(null)
const senses = ref(null)
const initialSlide = ref(0)
const isLoading = ref(false);

(() => {
  isLoading.value = true
  lookupWordSenses(props.word)
      .then(resp => {
        const sensesVal = resp.data
        sensesVal.forEach((sense, index) => {
          sense['swiper'] = {index, total: sensesVal.length}
        })

        if (props.initialSenseId) {
          initialSlide.value = Math.max(sensesVal.findIndex(s => s.id === props.initialSenseId), 0)
        }

        senses.value = sensesVal
      })
      .catch(error => console.log(error))
      .finally(() => isLoading.value = false)
})();

/*watch(() => props.word, (word) => {
  isLoading.value = true
  lookupWordSenses(word)
      .then(resp => {
        senses.value = resp.data
        senses.value.forEach((sense, index) => {
          sense['swiper'] = {index, total: senses.value.length}
        })
      })
      .catch(error => console.log(error))
      .finally(() => isLoading.value = false)
}, {immediate: true})*/


const activeIndex = ref(0)
const bulletLeft = ref(0)
const minBulletStart = 0
const maxBulletEnd = computed(() => senses.value.length - 1)
const visibleBulletStart = ref(0)
const visibleBulletEnd = computed(() => Math.min(visibleBulletStart.value + 4, visibleBulletStart.value + senses.value.length))


const onSlideChange = (() => {
  let oldActiveIndex = 0;

  return (swiper) => {
    const activeIndexVal = swiper.activeIndex
    activeIndex.value = activeIndexVal

    if (activeIndexVal > oldActiveIndex) {
      // Sliding to the right
      if (activeIndexVal !== maxBulletEnd.value && activeIndexVal >= 4 && activeIndexVal === visibleBulletEnd.value) {
        bulletLeft.value = bulletLeft.value - 14
        visibleBulletStart.value++
      }
    } else {
      // Sliding to the left
      if (activeIndexVal !== minBulletStart && activeIndexVal === visibleBulletStart.value) {
        bulletLeft.value = bulletLeft.value + 14
        visibleBulletStart.value--
      }
    }

    oldActiveIndex = activeIndexVal

    // console.log(activeIndexVal + '----' + visibleBulletStart.value + '----' + visibleBulletEnd.value)
  }
})();
</script>

<style lang="less" scoped>
#swiper {
  //padding: 0 0 30px 0;
  padding: 0 0 10px 0;
  //background-image: url("~@/assets/card-bg.jpg");
  //background-size: 100% auto;
  //background-repeat: no-repeat;

  #pagination {
    position: relative;
    height: 16px;
    transform: translateY(-8px);

    #pagination-wrapper {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      max-width: 90px;
      height: 16px;
      text-align: center;
      white-space: nowrap;
      display: flex;
      align-items: center;
      overflow: hidden;

      .pagination-bullet {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6px;
        min-width: 6px;
        height: 6px;
        font-size: 3px;
        color: transparent;
        border-radius: 50%;
        background: #484e52;
        margin: 0 4px;
        transition: left .35s, width .35s, min-width .35s, height .35s, font-size .35s, color .35s;

        &.core {
          background: #cc960c;
        }

        &.active {
          width: 26px;
          min-width: 26px;
          height: 16px;
          line-height: 16px;
          font-size: 9px;
          color: #f8f1df;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>

