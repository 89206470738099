import {baseUrl} from "@/utils/requests"
import defaultCover from '@/assets/default-sentence-cover.jpg'
import localforage from "localforage";

export const getSentenceCover = (sentence) => {
    const sourceImg = sentence.sourceImage
    if (sourceImg === '' || sourceImg === '*') {
        return defaultCover
    }
    return baseUrl + 'files/sentence-cover/' + sourceImg
}

export const getSentenceAudioBlob = async (sentence) => {
    return await ensureSentenceAudioCached(sentence)
}

export const getWordAudioBlob = async (word, us) => {
    return await ensureWordAudioCached(word, us)
}

export const ensureSentenceAudioCached = async (sentence) => {
    const cacheKey = sentence.sourceAudio
    return await ensureAudioCached(cacheKey, getSentenceAudioUrl(sentence))
}

export const ensureWordAudioCached = async (word, us) => {
    const cacheKey = `speeches/${us ? 'US' : 'UK'}/${word}.mp3`
    return await ensureAudioCached(cacheKey, getWordAudioUrl(word, us))
}

// =====================================================================================


// Safari浏览器的缓存策略对象不包括Audio, 但是可以利用IndexedDB等进行手动缓存
//  https://stackoverflow.com/questions/62971778/dealing-with-ios-web-browsers-not-caching-audio
async function ensureAudioCached(cacheKey, audioUrl) {
    try {
        let audioBlob = await localforage.getItem(cacheKey)
        if (audioBlob === null) {
            const resp = await fetch(audioUrl)
            if (!resp.ok) {
                throw new Error(`Failed to fetch audio. Status: ${resp.status}`)
            }
            audioBlob = new Blob([await resp.arrayBuffer()], {
                type: resp.headers.get('content-type') || 'audio/mpeg'
            })
            await localforage.setItem(cacheKey, audioBlob)
        }
        return audioBlob
    } catch (e) {
        console.error('Error caching audio:', e)
        return null
    }
}


function getSentenceAudioUrl(sentence) {
    return baseUrl + 'files/sentence-audio' + sentence.sourceAudio
}

function getWordAudioUrl(word, us) {
    return baseUrl + 'files/word-audio' +
        (us ?
            `/speeches/US/US-speech/${word}.mp3` :
            `/speeches/UK/UK-speech/${word}.mp3`)
}
