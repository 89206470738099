<template>
  <WordSenseSwiper
      @click="onClick"
      :word="word"
      :initialSenseId="initialSenseId"
      :initialSentenceId="initialSentenceId"/>
</template>

<script>
import WordSenseSwiper from "@/components/word-sense-swiper.vue";

const params = new URLSearchParams(window.location.search)
const pWord = params.get('word')
const pSenseId = params.get('senseid')
const pSentenceId = params.get('sentenceid')

const visibility = params.get('anki') === 'front' ? 'hidden' : null

export default {
  name: 'App',
  components: {
    WordSenseSwiper
  },
  data() {
    return {
      word: pWord,
      initialSenseId: pSenseId ? Number(pSenseId) : null,
      initialSentenceId: pSentenceId ? Number(pSentenceId) : null,
      visibility: visibility
    }
  }
}
</script>

<style lang="less">
#card {
  .sentence-swipe-item > .cn,
  #sense > #definition,
  #sense > #usages {
    visibility: v-bind(visibility);
  }
}

body {
  background-image: url("~@/assets/card-bg.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

#app {
  font-size: 16px;
  font-family: 'Inter', 'Noto Sans SC';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
