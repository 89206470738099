import axios from "axios";

export const baseUrl = 'https://bubeidanci-worker.20230617.date/'

const client = axios.create({
    baseURL: baseUrl,
    timeout: 15000
})

export default (url, method, submitData) => {
    return client({
        url,
        method,
        [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
    })
}


