import {createApp} from 'vue'
import App from './AnkiCard.vue'

// css
import 'normalize.css'
import './assets/base.less'
// vant
import '@vant/touch-emulator';  // https://vant-ui.github.io/vant/#/zh-CN/advanced-usage#zhuo-mian-duan-gua-pei
import {Swipe, SwipeItem, Overlay, Toast} from 'vant'
import 'vant/lib/index.css'

const app = createApp(App)
app.use(Swipe)
app.use(SwipeItem)
app.use(Overlay)
app.use(Toast)
app.mount('#app')

